@import "_libs.scss";
@import "_checkout_page.scss";

$background_color: #fff5f5;
$main_color: #363333;
$font_color: #363333;
$accent_color: #e2754d;
$accent_color2: #5b5597;
$secondary_color: #a0a0a0;

$transition: 0.6s;

:root {
  --color-text: #f6f1f1;
  --color-bg: #0f0e0e;

  --cursor-sphere-fill: transparent;
  --cursor-sphere-stroke: #ff4c57;
  --cursor-sphere-stroke-width: 1.5px;

  --cursor-fill: #e2754d;
}

/* Cursor CSS Start */
.cursor {
  display: none;
}

@media (any-pointer: fine) {
  .cursor,
  .cursor-sphere {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 33;
  }

  .cursor-sphere__inner {
    fill: var(--cursor-sphere-fill);
    stroke: var(--cursor-sphere-stroke);
    stroke-width: var(--cursor-sphere-stroke-width);
    opacity: 0.7;
    animation: rotationAnim 3s linear infinite;
    transform-origin: center;
  }

  .cursor__path {
    fill: var(--cursor-fill);
  }

  @keyframes rotationAnim {
    to {
      transform: rotate(360deg);
    }
  }
}
/* Cursor CSS End */

%h1 {
  font-family: "Novoposelensky", serif;
  font-weight: 400;
  font-size: 75px;
  line-height: 130%;
  text-transform: uppercase;
}
%h2 {
  font-family: "Novoposelensky", serif;
  font-weight: 400;
  font-size: 45px;
  line-height: 130%;
}
%h3 {
  font-family: "Novoposelensky", serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 130%;
}
%h4 {
  font-family: "Eskal Font4You";
  font-weight: 400;
  font-size: 35px;
  line-height: 150%;
  letter-spacing: 3.5px;
}
%h5 {
  font-family: "Eskal Font4You";
  font-weight: 400;
  font-size: 25px;
  line-height: 150%;
  letter-spacing: 2.5px;
}
%h6 {
  font-family: "Novoposelensky", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
%body1 {
  font-family: "GT Walsheim Pro";
  font-weight: 300;
  font-size: 24px;
  line-height: 140%;
}
%body2 {
  font-family: "GT Walsheim Pro";
  font-weight: 300;
  font-size: 22px;
  line-height: 140%;
}
%body3 {
  font-family: "GT Walsheim Pro";
  font-weight: 300;
  font-size: 18px;
  line-height: 140%;
}
%body4 {
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
}
%body5 {
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

h1,
.h1 {
  @extend %h1;
}

h2,
.h2 {
  @extend %h2;
}

h3,
.h3 {
  @extend %h3;
}

h4,
.h4 {
  @extend %h4;
}

h5,
.h5 {
  @extend %h5;
}

h6,
.h6 {
  @extend %h6;
}

.body1 {
  @extend %body1;
}

.body2 {
  @extend %body2;
}

.body3 {
  @extend %body3;
}

.body4 {
  @extend %body4;
}

.body5 {
  @extend %body5;
}

body {
  background-color: $background_color;
  font-family: "GT Walsheim Pro";
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
}

footer {
  flex-shrink: 0;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(10px);
  }
  100% {
    transform: translatey(0px);
  }
}

.banner {
  .banner_title {
    @extend %h1;
    margin-bottom: 0px;
    text-align: center;
    text-transform: uppercase;

    &:first-child {
      margin-top: 60px;
    }
  }
  .banner_img {
    display: block;
    margin: 0px auto;
    margin-top: -10px;
    max-width: 373px;
  }
  .scroll_text {
    width: 100%;
    @extend %body1;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-left: 10px;
      animation: float 6s ease-in-out infinite;
    }
    margin-bottom: 140px;
    & + svg {
      margin: 0px auto;
      display: block;
      margin-bottom: 140px;
    }
  }
}

.second_block {
  position: relative;
  z-index: 2;
  background: #e2754d;
  border-radius: 50px;
  padding-top: 204px;
  padding-bottom: 103px;

  .second_block_flex {
    display: flex;
    align-items: center;
    justify-content: center;
    .block {
      &:first-child {
        margin-right: 60px;
      }
      background: #fff5f5;
      border-top-left-radius: 40%;
      border-top-right-radius: 40%;
      text-align: center;
      width: 100%;
      max-width: 426px;
      padding-top: 86px;
      p {
        &:first-child {
          @extend %h4;
          color: $accent_color;
          margin-bottom: 9px;
          & + p {
            @extend %body1;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            min-height: 136px;
            margin-bottom: 33px;
            max-width: 320px;
          }
        }
      }

      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 297px;
        height: 68px;
        border-radius: 50px;
        background-color: $accent_color2;
        color: #fff5f5;
        @extend %body1;
        text-transform: uppercase;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 38px;
      }
    }
  }

  .second_block_second_flex {
    margin-bottom: 140px;
    margin-top: 140px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .left {
      width: 100%;
      max-width: 929px;
      p {
        color: #fff5f5;
        &:first-child {
          @extend %h2;
          margin-bottom: 35px;
          & + p {
            @extend %body1;
          }
        }
      }
    }
    .right {
      .for_button {
        a {
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 183px;
          height: 183px;
          border: 1px solid $background_color;
          border-radius: 50%;
          overflow: hidden;
          span {
            width: 142px;
            height: 142px;
            background-color: $accent_color2;
            display: flex;
            align-items: center;
            justify-content: center;
            @extend %body2;
            color: #fff5f5;
            border-radius: 50%;
            transition: 0.4s cubic-bezier(0.87, 0, 0.13, 1);
          }
          &:hover {
            span {
              width: 100%;
              height: 100%;
              // transform: scale(1.8);
              // font-size: 18px;
            }
          }
        }
      }
    }
  }

  .blocks_from_right,
  .block_form_left {
    margin-bottom: 177px;
    display: flex;
    flex-wrap: nowrap;
    .block {
      margin-right: 20px;
      &:last-child {
        margin-right: 0px;
      }
      padding-top: 34px;
      padding-left: 18px;
      padding-right: 18px;
      box-sizing: border-box;
      width: 548px;
      height: 277px;
      border: 1px solid $background_color;
      p {
        color: $background_color;
        &:first-child {
          @extend %h3;
          margin-bottom: 15px;
          & + p {
            @extend %body2;
          }
        }
      }
    }
  }

  .block_form_left {
    margin-bottom: 265px;
  }
}

.homepage_pricing {
  padding-top: 140px;
  background-color: #fff5f5;
  margin-top: -123px;
  position: relative;
  z-index: 2;
  border-radius: 50px;
  .homepage_pricing_title {
    @extend %h2;
    color: $main_color;
    margin-bottom: 70px;
  }

  .inherit_flex {
    margin-bottom: 82px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .block {
      transform-style: preserve-3d;
      position: relative;
      transform: rotate(2deg);
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        border: 1px solid $accent_color;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateZ(-10px) rotate(-9deg);
        border-radius: 10px;
        transition: 0.2s ease-in-out;
      }
      transition: 0.2s ease-in-out;
      &:hover {
        transform: rotate(0deg);
        &::after {
          transform: translateZ(-10px) rotate(0deg);
        }
      }
      border-radius: 10px;
      text-align: center;
      color: #fff5f5;
      width: 305px;
      background-color: $accent_color;
      border: 1px solid $accent_color;
      padding-top: 33px;
      padding-bottom: 33px;

      .h5 {
        color: $accent_color2 !important;
      }

      .h4 {
        @extend %h4;
        margin-bottom: 5px;
        // color: $accent_color2 !important;
        // & + p {
        //   @extend %h2;
        //   margin-bottom: 23px;
        // }
      }

      .h6 {
        margin-bottom: 20px;

        bdi {
          @extend %h6;
        }
      }

      // p {
      //   &:first-child {
      //     @extend %h4;
      //     margin-bottom: 10px;
      //     & + p {
      //       @extend %h2;
      //       margin-bottom: 23px;

      //       .amount,
      //       bdi {
      //         display: block;

      //         bdi {
      //           word-break: break-all;
      //         }
      //       }
      //     }
      //   }
      // }
      ul {
        li {
          text-align: center;
          @extend %body2;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 23px;
          }
        }
      }

      a {
        text-decoration: none;
        display: flex;
        width: 233px;
        height: 68px;
        justify-content: center;
        align-items: center;
        @extend %body1;
        color: $background_color;
        background-color: $accent_color2;
        border-radius: 50px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .for_button {
    margin-bottom: 140px;

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 183px;
      height: 183px;
      border: 1px solid $accent_color2;
      border-radius: 50%;
      overflow: hidden;
      margin: auto;

      span {
        width: 142px;
        height: 142px;
        background-color: $accent_color2;
        display: flex;
        align-items: center;
        justify-content: center;
        @extend %body2;
        color: #fff5f5;
        border-radius: 50%;
        transition: 0.4s cubic-bezier(0.87, 0, 0.13, 1);
      }
      &:hover {
        span {
          width: 100%;
          height: 100%;
          // transform: scale(1.8);
          // font-size: 18px;
        }
      }
    }
  }

  .per-minute {
    margin-bottom: 140px;

    .second_block_second_flex {
      display: flex;
      column-gap: 60px;

      .left {
        flex: 0 0 75%;
        max-width: 790px;

        span {
          max-width: 667px;
        }

        h2 {
          margin-bottom: 30px;
        }

        h4 {
          line-height: 33px;
          color: $accent_color;
        }
      }

      .right {
        flex: 0 0 23%;
        padding-top: 26px;

        h2 {
          color: $accent_color2;
        }

        p.body3 {
          margin-top: 12px;
          margin-bottom: 10px;
        }

        .body1 {
          white-space: nowrap;
        }

        .consultation-buy-btn {
          color: $background_color;
          @extend %body1;
          background-color: $accent_color2;
          transition: $transition;
          border: 1px solid transparent;
          width: 100%;
          text-align: center;
          border-radius: 50px;
          height: 68px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;

          &:hover,
          &:focus {
            text-decoration: none;
            transition: $transition;
            // border: 1px solid $background_color !important;
            background-color: $main_color;
          }
        }
      }
    }

    .product-quantity {
      display: flex;
      column-gap: 13px;
      margin-bottom: 50px;

      button {
        outline: none;
        border: none;
        background-color: $accent_color;
        padding: 0;
        position: relative;
        width: 63px;
        height: 63px;
        border-radius: 10px;
        transition: $transition;
        border: 1px solid $accent_color;

        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 35px;
          height: 3px;
          background-color: $background_color;
          border-radius: 10px;
          transition: $transition;
        }

        &:hover,
        &:focus {
          transition: $transition;
          background-color: $background_color;

          &::after,
          &::before {
            background-color: $accent_color;
            transition: $transition;
          }
        }
      }

      #quantity-plus-prod {
        &::after {
          transform: rotate(90deg);
        }
      }

      input {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
        flex: 0 0 calc(100% - 126px - 26px);
        width: calc(100% - 126px - 26px);
        text-align: center;
        border: 1px solid $accent_color;
        border-radius: 10px;
        background-color: transparent;
        @extend %h3;
        color: $accent_color;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }
      }
    }
  }
}

.key_facts_title {
  @extend %h2;
  margin-bottom: 70px;
}

.key_facts_flex {
  margin-bottom: 150px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  text-align: center;
  column-gap: 25px;

  div {
    h5 {
      color: $accent_color;
    }
    .body3 {
      color: $accent_color2;
      max-width: 270px;
      margin: 0 auto;
    }
    p {
      &:first-child {
        font-family: "Homemade Apple", cursive;
        color: $accent_color;
        font-size: 100px;
        line-height: 80px;
        & + p {
          @extend %body1;
          text-align: center;
        }
      }
    }
  }
}

.pre_footer_block {
  background-color: #fff5f5;
  padding-top: 140px;
  padding-bottom: 140px;
  text-align: center;
  .inherit_title {
    @extend %h2;

    &:last-of-type {
      margin-bottom: 35px;
    }

    & + p {
      @extend %h4;
      color: $accent_color;
      margin-bottom: 70px;
    }
  }

  .for_button {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 183px;
      height: 183px;
      border: 1px solid $accent_color2;
      border-radius: 50%;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      span {
        width: 142px;
        height: 142px;
        background-color: $accent_color2;
        display: flex;
        align-items: center;
        justify-content: center;
        @extend %body2;
        color: #fff5f5;
        border-radius: 50%;
        transition: 0.4s cubic-bezier(0.87, 0, 0.13, 1);
      }
      &:hover {
        span {
          width: 100%;
          height: 100%;
          // transform: scale(1.8);
          // font-size: 18px;
        }
      }
    }
  }
}

.order-process {
  margin-top: 140px;
  @extend %body1;

  &_title {
    margin-bottom: 35px;
  }

  h4 {
    color: $accent_color;
  }

  .col-lg-7 {
    display: flex;
    align-items: center;
  }

  &_content {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

footer {
  margin-top: -30px;
  background-color: $main_color;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  padding-top: 35px;
  padding-bottom: 35px;
  text-align: center;

  svg {
    & + p {
      text-align: center;
      @extend %body2;
      color: $background_color;
      margin-bottom: 20px;
      & + a {
        text-align: center;
        @extend %h4;
        color: $accent_color;
        text-decoration: none;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;

        &:hover {
          color: $accent_color2;
          text-decoration: none;
        }
      }
    }
  }

  .payments-block {
    text-align: left;
    margin-top: 20px;

    .body5 {
      color: $secondary_color;
      margin-bottom: 3px;
    }

    .body4 {
      color: $background_color;
    }

    .left-side {
      display: flex;
      align-items: flex-end;
      column-gap: 30px;
    }

    .right-side {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      flex-direction: column;
      row-gap: 10px;

      svg {
        margin: 0;
      }
    }
  }
}

.pricing_wrapper {
  .second_block {
    position: relative;
    z-index: 2;
    background: #e2754d;
    border-radius: 50px;
    padding-top: 204px;
    padding-bottom: 193px;

    padding-top: 70px;
    padding-bottom: 160px;

    .second_block_flex {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      color: $background_color;
      display: block;

      .block {
        &:first-child {
          margin-right: 60px;
        }
        background: #fff5f5;
        border-top-left-radius: 40%;
        border-top-right-radius: 40%;
        text-align: center;
        width: 100%;
        max-width: 426px;
        padding-top: 86px;
        p {
          &:first-child {
            @extend %h4;
            color: $accent_color;
            margin-bottom: 9px;
            & + p {
              @extend %body1;
              text-align: center;
              margin-left: auto;
              margin-right: auto;
              min-height: 136px;
              margin-bottom: 33px;
              max-width: 320px;
            }
          }
        }

        a {
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 297px;
          height: 68px;
          border-radius: 50px;
          background-color: $accent_color2;
          color: #fff5f5;
          @extend %body1;
          text-transform: uppercase;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 38px;
        }
      }

      h2 {
        margin-bottom: 35px;
      }
    }

    .second_block_second_flex {
      margin-bottom: 140px;
      margin-top: 140px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .left {
        width: 100%;
        max-width: 792px;
        p {
          color: #fff5f5;
          &:first-child {
            @extend %h2;
            margin-bottom: 35px;
            & + p {
              @extend %body1;
            }
          }
        }
      }
      .right {
        .for_button {
          a {
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 183px;
            height: 183px;
            border: 1px solid $background_color;
            border-radius: 50%;
            overflow: hidden;
            span {
              width: 142px;
              height: 142px;
              background-color: $accent_color2;
              display: flex;
              align-items: center;
              justify-content: center;
              @extend %body2;
              color: #fff5f5;
              border-radius: 50%;
              transition: 0.4s cubic-bezier(0.87, 0, 0.13, 1);
            }
            &:hover {
              span {
                width: 100%;
                height: 100%;
                // transform: scale(1.8);
                // font-size: 18px;
              }
            }
          }
        }
      }
    }

    .blocks_from_right,
    .block_form_left {
      margin-bottom: 177px;
      display: flex;
      flex-wrap: nowrap;
      .block {
        margin-right: 20px;
        &:last-child {
          margin-right: 0px;
        }
        padding-top: 34px;
        padding-left: 18px;
        padding-right: 18px;
        box-sizing: border-box;
        width: 548px;
        height: 277px;
        border: 1px solid $background_color;
        p {
          color: $background_color;
          &:first-child {
            @extend %h3;
            margin-bottom: 15px;
            & + p {
              @extend %body2;
            }
          }
        }
      }
    }

    .block_form_left {
      margin-bottom: 265px;
    }
  }

  .homepage_pricing {
    padding-top: 140px;
    background-color: #fff5f5;
    position: relative;
    margin-top: -90px;
    z-index: 2;
    border-radius: 50px;

    .homepage_pricing_title {
      @extend %h2;
      color: $main_color;
      margin-bottom: 70px;
    }

    .inherit_flex {
      margin-bottom: 157px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .block {
        transform-style: preserve-3d;
        position: relative;
        transform: rotate(2deg);
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          border: 1px solid $accent_color;
          position: absolute;
          top: 0;
          left: 0;
          transform: translateZ(-10px) rotate(-9deg);
          border-radius: 10px;
          transition: 0.2s ease-in-out;
        }
        transition: 0.2s ease-in-out;
        &:hover {
          transform: rotate(0deg);
          &::after {
            transform: translateZ(-10px) rotate(0deg);
          }
        }
        border-radius: 10px;
        text-align: center;
        color: #fff5f5;
        width: 305px;
        background-color: $accent_color;
        border: 1px solid $accent_color;
        padding-top: 33px;
        padding-bottom: 33px;

        .h5 {
          color: $accent_color2 !important;
        }

        .h4 {
          @extend %h4;
          margin-bottom: 0;
          color: $accent_color2 !important;
          & + p {
            @extend %h2;
            margin-bottom: 23px;
          }
        }

        .h6 {
          bdi {
            @extend %h6;
          }
          margin-bottom: 17px;
        }

        // p {
        //   &:first-child {
        //     @extend %h4;
        //     margin-bottom: 10px;
        //     & + p {
        //       @extend %h2;
        //       margin-bottom: 23px;
        //     }
        //   }
        // }
        ul {
          li {
            text-align: center;
            @extend %body2;
            margin-bottom: 10px;
            &:last-child {
              margin-bottom: 23px;
            }
          }
        }

        a {
          text-decoration: none;
          display: flex;
          width: 233px;
          height: 68px;
          justify-content: center;
          align-items: center;
          @extend %body1;
          color: $background_color;
          background-color: $accent_color2;
          border-radius: 50px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .item_wraper {
      border-radius: 10px;
      background-color: $accent_color;
      color: $background_color;
      margin-bottom: 247px;

      > .d-flex {
        padding: 30px 61px;
      }

      .left-side {
        min-width: min(425px, 100%);

        .h4 {
          color: $accent_color2;
        }
      }

      .right-side {
        li {
          position: relative;
          padding-left: 27px;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          &::after {
            content: "";
            position: absolute;
            top: 6px;
            left: 0;
            // transform: translateY(-50%);
            width: 12px;
            height: 12px;
            background-color: $accent_color2;
            border-radius: 100%;
          }
        }
      }

      .bottom-side {
        margin-top: 45px;
        display: flex;
        justify-content: space-evenly;
        column-gap: 50px;
        margin-bottom: 155px;

        .cart {
          background-color: $background_color;
          border: 1px solid $accent_color;
          border-radius: 10px;
          padding: 20px 16px;
          text-align: center;
          color: $accent_color;
          margin-bottom: -160px;
          transform: rotate(-7deg);
          transition: $transition;

          h6 {
            margin-top: 15px;
            margin-bottom: 30px;
            color: $accent_color2;
          }

          .add_to_cart_button {
            color: $background_color;
            background-color: $accent_color2;
            border-radius: 50px;
            @extend %body1;
            padding: 17px 59px;
            transition: $transition;
            display: inline-flex;
            align-items: center;

            &:hover,
            &:focus {
              transition: $transition;
              background-color: $accent_color;
              text-decoration: none;
            }
          }

          &:hover,
          &:focus {
            transition: $transition;
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}

.calculator {
  &_wrap {
    background-color: $accent_color;
    color: $background_color;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    column-gap: 86px;
    padding: 70px 121px 70px 36px;
    margin-bottom: 140px;
  }

  &_left-side {
    max-width: 486px;

    h2 {
      margin-bottom: 35px;
    }

    p {
      max-width: 467px;
    }
  }

  &_right-side {
    max-width: 427px;

    h3 {
      margin-bottom: 35px;
    }

    .body5 {
      display: block;
    }

    .select {
      height: 60px;
      background-color: $background_color;
      margin-bottom: 10px;
    }

    .select-styled {
      display: flex;
      align-items: center;
      background-color: $background_color;
      color: $main_color;

      &.active,
      &:active {
        &:after {
          transform: rotate(180deg);
        }
      }

      &:after {
        background-size: 18px 10px;
        background-position: center;
        background-repeat: no-repeat;
        width: 18px;
        height: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    .select-options {
      background-color: $background_color;
      color: $accent_color2;

      li {
        background-color: $background_color;
        color: $accent_color2;

        &:hover,
        &:focus {
          background-color: $accent_color2;
          color: $background_color;
        }
      }
    }

    .word-length_wrap {
      position: relative;

      button {
        position: absolute;
        right: 21px;
        background-color: transparent;
        outline: none;
        padding: 0;
        margin: 0;
        border: none;

        * {
          pointer-events: none;
        }

        &.number {
          &-up {
            top: 19px;
          }

          &-down {
            top: 30px;
          }
        }
      }

      input {
        padding: 18px 30px 17px 22px;
        width: 100%;
        background-color: $background_color;
        border: 1px solid $main_color;
        border-radius: 20px;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    .radio_wrap {
      margin-top: 21px;

      input {
        opacity: 0.01;
        width: 13px;
        height: 13px;

        &:checked + label {
          color: $main_color;

          &::after {
            background-color: $main_color;
            transform: translateY(-50%) scale(1);
          }
        }
      }

      label {
        display: inline-block;
        position: relative;
        padding-left: 28px;
        margin-left: -13px;
        cursor: pointer;
        margin-right: 30px;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 23px;
          height: 23px;
          border: 1px solid $main_color;
          border-radius: 100%;
          background-color: $background_color;
          transition: $transition;
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 4px;
          transform: translateY(-50%) scale(0.01);
          border-radius: 100%;
          background-color: $main_color;
          width: 15px;
          height: 15px;
          transition: $transition;
        }

        &:last-child {
          margin-right: 70px;
        }
      }
    }

    .price_wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: baseline;
      margin-top: 30px;
      margin-bottom: 30px;

      h3 {
        margin-bottom: 0;
      }

      bdi {
        color: $accent_color2;
      }
    }

    .button_wrap {
      .add_to_cart_button {
        width: 100%;
        border-radius: 50px;
        padding: 17px 25px;
        text-align: center;
        background-color: $accent_color2;
        color: $background_color;
        transition: $transition;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover,
        &:focus {
          transition: $transition;
          background-color: $main_color;
          color: $background_color;
          text-decoration: none;
        }
      }
    }
  }
}

.categories_wrapper {
  .second_block {
    padding-bottom: 193px;
  }
  .catagories_third_block {
    margin-top: -90px;
    position: relative;
    z-index: 3;
    background-color: #fff5f5;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    .inherit_title {
      @extend %h2;
      padding-top: 140px;
      margin-bottom: 70px;
      max-width: 973px;
      text-transform: uppercase;
    }

    .third_block_flex {
      .like_row {
        border-top: 1px solid $accent_color;
        padding: 50px 0px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        &:last-child {
          border-bottom: 1px solid $accent_color;
          margin-bottom: 140px;
        }
        .left {
          p {
            font-family: "GT Walsheim Pro";
            font-weight: 500;
            font-size: 30px;
            line-height: 39px;
            text-transform: uppercase;
            color: $accent_color;
          }
        }
        .right {
          max-width: 670px;
          width: 100%;
          p {
            @extend %body1;
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
}

.faq_wrapper {
  .faq_container {
    background-color: $accent_color;
    border-radius: 50px;
    padding-top: 140px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    .question_container {
      border-top: 1px solid #fff5f5;
      &:last-child {
        border-bottom: 1px solid #fff5f5;
        margin-bottom: 140px;
      }
      .question {
        padding-top: 50px;
        font-family: "GT Walsheim Pro";
        font-weight: 500;
        font-size: 30px;
        line-height: 39px;
        text-transform: uppercase;
        color: $background_color;
      }
      .answer {
        margin-left: 75px;
        margin-top: 25px;
        margin-bottom: 50px;
        @extend %body2;
        color: $background_color;
        p {
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
        a {
          color: $accent_color2;
        }
      }
    }
  }
}

.contact_banner {
  text-align: center;
  .contact_title {
    margin-top: 60px;
    margin-bottom: 25px;
    @extend %h2;
    color: $main_color;
    text-transform: uppercase;
    & + svg {
      margin-bottom: 35px;
    }
  }

  .for_form {
    max-width: 670px;
    margin-left: auto;
    margin-right: auto;
    input[type="text"],
    input[type="email"],
    textarea {
      width: 100%;
      height: 64px;
      box-sizing: border-box;
      padding: 22px;
      &::placeholder {
        color: $secondary_color;
      }
      color: $main_color;
      @extend %body3;
      border: 1px solid $main_color;
      border-radius: 20px;
      outline: none;
      background-color: transparent;
    }

    input[type="submit"] {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 68px;
      width: 271px;
      border: none;
      outline: none;
      background-color: $accent_color2;
      color: #fff5f5;
      border-radius: 50px;
      @extend %body1;
      text-transform: uppercase;
      margin: 0px auto;
      margin-top: 30px;
      margin-bottom: 70px;
      // margin-bottom: 45px;
    }

    textarea {
      resize: none;
      height: 106px;
    }

    .two_input {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      span {
        display: block;
        width: 100%;
        max-width: 305px;
      }
    }
  }

  .for_policy {
    max-width: 670px;
    margin: auto;
    margin-bottom: 140px;

    a {
      color: $accent_color2;
      transition: $transition;

      &:hover,
      &:focus {
        color: $accent_color;
        transition: $transition;
      }
    }
  }
}

$select-color: #000;
$select-background: transparent;
$select-width: 100%;
$select-height: 64px;

.select {
  // border-radius: 30px;
  // margin-top: 12px;
  // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
}

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: $select-color;
  width: $select-width;
  background-color: $accent_color;
  max-width: 670px;
  height: $select-height;
  border: 1px solid $font_color;
  box-sizing: border-box;
  border-radius: 20px;
  margin-bottom: 20px;
  text-align: left;
}
.select-styled {
  box-sizing: border-box !important;
  border-radius: 30px;
  // border: 0px solid transparent;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $select-background;
  padding: 14px 15px;
  transition: all 0.2s ease-in;
  color: $background_color;
  @extend %body1;
  &:after {
    content: "";
    //   width: 0;
    //   height: 0;
    //   border: 7px solid transparent;
    //   border-color: $select-color transparent transparent transparent;
    position: absolute;
    transition: 0.3s;
    //   top: 16px;
    //   right: 10px;
    display: block;
    width: 24px;
    height: 14px;
    background-image: url("../img/fa_select.svg");
    background-size: cover;
    top: 26px;
    right: 15px;
  }
  &:hover {
  }
  &:active,
  &.active {
    // border: 2px solid #FFD575;
    &:after {
      transform: rotate(135deg);
    }
  }
}

.select-options {
  display: none;
  position: absolute;
  top: 110%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background: $accent_color;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  // border: 1px solid #DFDFDF;
  box-sizing: border-box;
  border-radius: 20px;
  border: 1px solid $main_color;
  overflow: hidden;
  li {
    margin: 0;
    padding: 12px 0;
    text-indent: 15px;
    border-top: 1px solid darken($select-background, 10);
    transition: all 0.15s ease-in;
    @extend %body3;
    color: #fff5f5;
    &:hover {
      color: $main_color;
      background: $background_color;
    }
    &[rel="hide"] {
      display: none;
    }
  }
}

.login_title {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 35px;
  @extend %h2;
  text-transform: uppercase;
}
.for_register {
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 160px;
  form {
    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      li {
        width: 100%;
        max-width: 305px;
        input[type="text"],
        input[type="email"],
        input[type="password"] {
          width: 100%;
          height: 64px;
          box-sizing: border-box;
          padding: 22px;
          &::placeholder {
            color: $secondary_color;
          }
          color: $main_color;
          @extend %body3;
          border: 1px solid $main_color;
          border-radius: 20px;
          outline: none;
          background-color: transparent;
        }
      }

      .wppb-gdpr-checkbox {
        width: 100%;
        max-width: 100% !important;
        margin-bottom: 30px !important;

        label {
          position: absolute;
          padding-left: 32px;
          @extend %body2;
          font-size: 21px;
        }

        .custom-checkbox {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 22px;
          height: 22px;
          border: 1px solid $main_color;
          border-radius: 4px;
          box-sizing: border-box;
          transition: 0.2s ease-in-out;
        }

        input {
          @extend %body2;
          position: absolute;
          left: -9999999px;
          &:checked {
            & + .custom-checkbox {
              background-color: $accent_color;
            }
          }
        }
      }
    }

    input[type="submit"] {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 68px;
      width: 271px;
      border: none;
      outline: none;
      background-color: $accent_color2;
      color: #fff5f5;
      border-radius: 50px;
      @extend %body1;
      text-transform: uppercase;
    }

    .form-submit {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        text-transform: uppercase;
        @extend %body2;
        color: $main_color;
        position: relative;
        text-decoration: none;
        text-decoration: none;
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          background-color: $main_color;
          position: absolute;
          left: 0;
          bottom: -1px;
          transition: 0.2s ease;
        }
        &:hover {
          &::after {
            width: 70%;
          }
        }
      }
    }
  }
}
.for_login {
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 160px;
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .login-username,
    .login-password {
      width: 100%;
      max-width: 305px;
    }

    .login-remember {
      margin-top: 15px !important;
      width: 100%;
      display: flex;
      justify-content: space-between;
      input {
        position: absolute;
        left: -99999999px;
        &:checked {
          & + label {
            &::before {
              background-color: $accent_color;
            }
          }
        }
      }

      a {
        @extend %body2;
        color: $accent_color2;
        text-decoration: none;
      }

      label {
        @extend %body2;
        cursor: pointer;
        padding-left: 32px !important;
        position: relative;
        &::before {
          content: "";
          display: block;
          width: 22px;
          height: 22px;
          border: 1px solid $main_color;
          box-sizing: border-box;
          position: absolute;
          left: 0px;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 5px;
          transition: 0.2s ease-in-out;
        }
      }
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
      width: 100%;
      height: 64px;
      box-sizing: border-box;
      padding: 22px;
      &::placeholder {
        color: $secondary_color;
      }
      color: $main_color;
      @extend %body3;
      border: 1px solid $main_color;
      border-radius: 20px;
      outline: none;
      background-color: transparent;
    }

    input[type="submit"] {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 68px;
      width: 271px;
      border: none;
      outline: none;
      background-color: $accent_color2;
      color: #fff5f5;
      border-radius: 50px;
      @extend %body1;
      text-transform: uppercase;
    }

    .login-submit {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px !important;
      width: 100%;

      a {
        text-transform: uppercase;
        @extend %body2;
        color: $main_color;
        position: relative;
        text-decoration: none;
        text-decoration: none;
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          background-color: $main_color;
          position: absolute;
          left: 0;
          bottom: -1px;
          transition: 0.2s ease;
        }
        &:hover {
          &::after {
            width: 70%;
          }
        }
      }
    }
  }
}
.login-register-lost-password {
  display: none;
}
.show-hide {
  border: none;
  outline: none;
  background-color: transparent;
}

.account-page {
  margin-bottom: 160px;

  form {
    ul {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .wppb-form-field {
    opacity: 0;
    height: 0;
    padding: 0;
    max-width: 0;
    //   transition: $transition;
    margin-top: 0;

    input,
    label {
      width: 100%;
    }

    input {
      padding: 0;
    }

    &.show {
      margin-left: 15px;
      margin-right: 15px;

      &:not(.wppb-heading) {
        display: inline-block;
        // margin-top: 16px;
        width: calc(50% - 30px);
        opacity: 1;
        height: auto;
        // padding: 15px 0;
        max-width: unset;

        &.wppb-delete-account-box {
          width: 100%;
          margin: 0;

          p {
            margin-bottom: 10px;
            @extend %body2;
          }

          .button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 68px;
            width: 271px;
            border: none;
            outline: 0;
            background-color: #5b5597;
            color: #fff5f5;
            border-radius: 50px;
            text-transform: uppercase;
            margin-bottom: 160px;
            margin-top: 30px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }

      input {
        // padding: 9px 23px;
      }
    }
  }
  .wppb-heading {
    display: none;
    width: 0;
  }

  .hideButton {
    .form-submit {
      display: none !important;
    }
  }

  .form-submit {
    text-align: left;

    input {
      margin-left: 0 !important;
    }
  }

  .logout {
    pointer-events: all;
    color: $main_color;
    @extend %body2;
    // text-decoration: underline;
    border-bottom: 1px solid $main_color;

    &-box {
      text-align: right;
      margin-top: -43px;
      margin-right: calc(16.6% + 30px);
      pointer-events: none;
      position: relative;
      z-index: 1;
    }
  }

  .wppb-form-field.pbpl-class label[for="delete-acc-pass"] {
    text-indent: 0px;
    position: relative;
    left: 0;

    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    height: 0;
    transition: $transition;
    color: $accent_color;

    @extend %body3;
    font-size: 0px;
    line-height: 0px;

    &.show {
      visibility: visible;
      height: 16px;
      opacity: 1;
      pointer-events: all;
      @extend %body3;
    }
  }
}
#wppb-form-element-21 {
  display: none;
}
.account-page ul.acc-header {
  li {
    margin-right: 0px;
    // margin: 0px 30px;
  }
  display: flex;
  justify-content: space-between;
  margin-left: 8.3%;
  margin-right: 9.3%;

  margin-top: 60px;
  margin-bottom: 60px;
  button {
    background-color: transparent;
    border: none;
    outline: none;
    h4 {
      // font-family: "GT Walsheim Pro";
      // font-style: normal;
      // font-weight: 500;
      // font-size: 30px;
      // line-height: 130%;
      /* identical to box height, or 39px */
      // white-space: nowrap;

      @extend %h3;

      text-transform: uppercase;

      /* main_color */

      color: #282828;
    }
  }
  .current {
    h4 {
      color: $accent_color;
    }
  }
}

#wppb-edit-user-account {
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
  li {
    padding-bottom: 15px;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    width: 100%;
    height: 64px;
    box-sizing: border-box;
    padding: 22px;
    &::placeholder {
      color: $secondary_color;
    }
    color: $main_color;
    @extend %body3;
    border: 1px solid $main_color;
    border-radius: 20px;
    outline: none;
    background-color: transparent;
  }

  input[type="submit"] {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 68px;
    width: 271px;
    border: none;
    outline: none;
    background-color: $accent_color2;
    color: #fff5f5;
    border-radius: 50px;
    @extend %body1;
    text-transform: uppercase;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }
}

.policy_title {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 35px;
  @extend %h1;
  text-transform: uppercase;
}

.policy_content {
  max-width: 1156px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 150px;
  @extend %body1;
  p,
  ul,
  ol {
    margin-bottom: 15px;
  }
  ul,
  ol {
    margin-left: 30px;
    li {
      margin-bottom: 10px;
    }
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }

  h2 {
    margin-top: 50px;
    margin-bottom: 25px;
    @extend %h3;
    text-transform: uppercase;
  }
}

.burger_container {
  position: relative;
  width: 30px;
  height: 30px;
  //background-color: pink;
  transform-style: preserve-3d;
  perspective: 200px;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: none;
  //border: 1px solod red;
  border: none;
  cursor: pointer;
  transition: transform 0.2s linear;
  //background-color: green;
}

.line {
  display: block;
  width: 30px;
  height: 2px;
  background-color: red;
}

.button-open {
  justify-content: flex-start;
  align-items: start;
  &__line {
    margin-bottom: 10px;
    &:last-of-type {
      width: 15px;
      margin-bottom: 0;
    }
  }
}

.button-close {
  justify-content: center;
  transform-origin: top;
  // transform: ;
  transform: translateY(100%) rotateX(-90deg);
  opacity: 0;
  &__line {
    &:nth-child(1) {
      transform: translateY(2px) rotate(45deg);
    }
    &:nth-child(2) {
      transform: rotate(-45deg);
    }
  }

  &.button-front {
    opacity: 1;
  }
}

.button-front {
  transform: translateY(-100%);
  transform: rotate(0);
}

.button-back {
  transform: translateY(-100%);
  transform: rotateX(90deg);
}

.header_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .language-con {
    margin-left: 15px;
    margin-right: 21px;
    padding: 5px 10px;
    background-color: $accent_color2;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:hover) {
      a:not(.current_lang) {
        opacity: 0;
        pointer-events: none;
        height: 0;
      }

      .current_lang {
        &::after {
          width: 0;
        }
      }
    }

    a {
      @extend %body3;
      color: $background_color;
      transition: $transition;
      position: relative;
      display: block;
      width: 100%;
      text-align: center;
      height: 25px;

      &.current_lang {
        &::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          width: 30px;
          height: 1px;
          background-color: $accent_color;
          transition: $transition;
        }
      }

      &:hover,
      &:focus {
        text-decoration: none;
        color: $accent_color;
        transition: $transition;
      }
    }

    .current_lang {
      order: -1;
    }
  }

  .currency-switcher {
    margin-left: auto;
    margin-right: 0;
    padding: 5px 12px;
    background-color: $accent_color2;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      text-decoration: none;
      @extend %body3;
      color: $background_color;
      transition: $transition;
      position: relative;
      display: block;
      width: 100%;
      text-align: center;
      height: 25px;

      &:not(:hover) {
        opacity: 0;
        pointer-events: none;
        height: 0;
      }

      &:hover,
      &:focus {
        text-decoration: none;
        color: $accent_color;
        transition: $transition;
      }

      &.current-currency {
        height: 25px !important;
        opacity: 1;
        pointer-events: all;
      }
    }

    &:hover,
    &:focus {
      a {
        opacity: 1;
        height: 25px;
        pointer-events: all;
      }
    }
  }

  .button_trigger {
    width: 72px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $accent_color;
    .burger_container {
      width: 40px;
      height: 30px;
      span {
        color: #fff5f5;
      }
      .line {
        background-color: #fff5f5;
      }
    }
  }
}

header {
  position: fixed;
  top: 25px;
  z-index: 999;

  pointer-events: none;

  * {
    pointer-events: none;
  }

  a,
  p,
  button,
  .button_trigger,
  .currency-switcher,
  .language-con {
    pointer-events: all;
  }

  .button {
    background-color: transparent !important;
    padding: 0 !important;
  }
}

.hidden_header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 998;
  transform: translateY(-100%);
  background-color: $accent_color;
  padding-top: 124px;

  .second_header_flex {
    align-content: stretch;
    display: flex;
    justify-content: space-between;
    .left {
      .item {
        text-decoration: none;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 50px;
        &:last-child {
          margin-bottom: 0px;
        }
      }

      .dot {
        height: 20px;
        min-width: 20px;
        background-color: #fff5f5;
        border-radius: 50%;
        margin-right: 10px;
      }

      .text {
        @extend %h2;
        text-transform: uppercase;
        color: $background_color;
      }
    }

    .right {
      height: 100%;
      div {
        text-align: right;
        margin-bottom: 150px;
        & + a {
          @extend %h2;
          display: inline-block;
          position: relative;
          color: $background_color;
          text-decoration: none;
          margin-left: auto;
          text-transform: uppercase;
          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 4px;
            background-color: $background_color;
            position: absolute;
            bottom: -2px;
            left: 0;
            transition: 0.2s ease-in-out;
          }
          &:hover {
            &::after {
              width: 65%;
            }
          }
        }
        a {
          &:first-child {
            padding: 17px 95px;
            background-color: $accent_color2;
            border-radius: 50px;
            @extend %body1;
            color: $background_color;
            text-decoration: none;
            text-transform: uppercase;
            margin-bottom: 25px;
            display: inline-block;
            border: 1px solid $accent_color2;
          }
          &:nth-child(3) {
            padding: 17px 95px;
            background-color: transparent;
            border-radius: 50px;
            @extend %body1;
            color: $background_color;
            text-decoration: none;
            text-transform: uppercase;
            margin-bottom: 25px;
            display: inline-block;
            border: 1px solid $background_color;
          }
        }
      }
    }
  }

  .second_header_second_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    ul {
      li {
        display: inline-block;
        margin-right: 42px;
        a {
          @extend %body2;
          text-decoration: none;
          color: $background_color;
        }
      }
      & + a {
        text-decoration: none;
      }
    }
  }

  .mailto {
    color: $accent_color2;
    transition: $transition;

    &:hover,
    &:focus {
      transition: $transition;
    }
  }
}
.pre_footer_block .inherit_title {
  .char28 {
    display: block;
    height: 0px;
  }
}

.homepage_wrapper {
  .banner_title {
    .char19 {
      display: block;
      height: 0px;
    }
  }
}

.banner_title,
.pre_footer_block .inherit_title {
  animation: tilting 8s cubic-bezier(0.37, 0, 0.63, 1) infinite;
  span {
    transition: 0.2s ease-in-out;
    display: inline-block;
    margin: 0px 2px;
    animation: floating-secondary 5s cubic-bezier(0.37, 0, 0.63, 1) infinite;
    &:nth-child(odd) {
      animation-delay: 0.5s;
    }
    &:hover {
      font-weight: 100;
    }
  }

  // @for $i from 1 to 100 {
  //   $item: $i;
  //   span {
  //     &:nth-child(#{$item}) {
  //       animation-delay: #{$item}s;
  //     }
  //   }
  // }
}
.pre_footer_block .inherit_title {
  // max-width: 100px;
}

body {
  overflow-x: hidden;
}

.marquee-infinite {
  max-width: 100%;
  overflow: hidden;
  // max-height: 64px;
}

/*Здесь можно поиграть со свойством width: mac-content*/
.marquee-infinite div {
  display: flex;
  width: 200%;
  animation: marquee-infinite 40s linear infinite;
  align-items: center;
  // height: 64px;
}

.marquee-infinite p {
  white-space: nowrap;
  // width: 100%;
  font-weight: bold;
  text-align: center;
  margin: 0px 50px;
  @extend %h1;
  text-transform: uppercase;
  color: $background_color;
  // font-size: 16px;
  // // line-height: 22px;
  // text-transform: uppercase;
  // color: rgba(255, 255, 255, 0.5);
}

.marquee-infinite span {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.marquee-infinite img {
  width: 20%;
}

.marquee-infinite:hover div {
  animation-play-state: paused;
}

@keyframes marquee-text {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee-infinite {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.second_block {
  .marquee-infinite {
    position: absolute;
    &:first-child {
      opacity: 0.3;
      top: 48px;
    }
    &:nth-child(2) {
      opacity: 0.2;
      top: 165px;
      div {
        animation-duration: 50s;
        animation-direction: reverse;
      }
    }
    &:nth-child(3) {
      opacity: 0.1;
      top: 280px;
      animation-duration: 60s;
    }
  }
}

.slick {
  &-slide {
  }
}

.for_slider_block {
  .slide_content {
    &:hover {
      // zoom: 2;
      // transform: scale(1.1);
      p {
        &:nth-child(2) {
          transform: rotate(0deg);
        }
      }
    }
    height: 270px;
    width: 548px;
    border-radius: 10px;
    background-color: $accent_color;
    border: 1px solid $accent_color;
    position: relative;
    img {
      position: absolute;
      left: 20px;
      top: -33px;
    }

    p {
      &:nth-child(2) {
        transition: 0.2s ease;
        display: inline-block;
        padding: 9px 75px;
        background-color: $accent_color2;
        border: 3px solid $background_color;
        border-radius: 10px;
        font-family: "GT Walsheim Pro";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: $accent_color;
        position: absolute;
        top: -23px;
        left: 30%;
        transform: rotate(-3deg);
        & + p {
          margin-top: 45px;
          margin-bottom: 3px;
          @extend %h5;
          color: $background_color;
          margin-left: auto;
          padding-right: 17.5px;
          text-align: right;
          & + p {
            @extend %body2;
            color: $background_color;
            padding-left: 17px;
            padding-right: 18px;
          }
        }
      }
    }
  }
}
.slick-list {
  padding: 50px 5% 50px 30px;
}

.slick-slide {
  &:focus-visible {
    outline: unset !important;
  }
}

.hidden_header .second_header_flex .right div a:nth-child(3) {
  transition: 1s ease-in-out;
  &:hover {
    background-color: $accent_color2;
  }
}

#add_payment_method .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-checkout .wc-proceed-to-checkout a.checkout-button {
  background-color: transparent !important;
  @extend %body2;
  border: 1px solid $accent_color2;
  border-radius: 50px;
  color: $main_color !important;
  transition: 0.2s ease-in-out;
  &:hover {
    background-color: $accent_color2 !important;
    border-color: #fff5f5 !important;
    color: #fff5f5 !important;
  }
}

.banner {
  .like_svg {
    @extend %h4;
    color: $accent_color;
    margin-bottom: 140px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    max-width: 920px;
    padding: 5px;
    padding-bottom: 10px;
    // animation: typing 2s steps(12),
    // blink .5s infinite step-end alternate;
  }
}

@keyframes typing {
  from {
    width: 0px;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.for_slider_block .slide_content {
  transition: 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
}

.contact_text {
  @extend %h4;
  color: $accent_color;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 35px;
  min-height: 104px;
}

.for_login form input[type="submit"],
.for_register form input[type="submit"] {
  position: relative;
}

.second_block .second_block_flex .block a,
.homepage_pricing .inherit_flex .block a,
.for_login form input[type="submit"],
.hidden_header .second_header_flex .right div a:first-child,
.pricing_wrapper .homepage_pricing .inherit_flex .block a,
.contact_banner .for_form input[type="submit"],
.for_register form input[type="submit"] {
  transition: 0.6s ease-in-out;
  border: 1px solid transparent;
  &:hover {
    background-color: $main_color;
    // border: 1px solid #fff5f5 !important;
  }
}

.page-id-20 {
  .banner_title {
    .char15 {
      display: block;
      height: 0px;
    }
  }
}

.page-id-22 {
  .banner_title {
    .char22 {
      display: block;
      height: 0px;
    }
  }
}

.page-id-24 {
  .banner_title {
    .char20 {
      display: block;
      height: 0;
    }
  }
}

*::selection {
  background-color: $secondary_color;
  color: $background_color;
}

.ti-cursor {
  display: none !important;
}

.pre_footer_block .inherit_title {
  text-transform: uppercase;
}

footer svg + p + a {
  transition: 0.2s ease-in-out;
  &:hover {
    // color: $accent_color;
  }
}

.homepage_pricing .homepage_pricing_title,
.key_facts_title {
  text-transform: uppercase;
}

.wppb-gdpr-checkbox {
  a {
    color: $accent_color;
  }
}

#wppb-edit-user-account {
  input[type="submit"] {
    position: relative;
  }
}

.error404 {
  background-color: $accent_color;
}
.error_container {
  color: $background_color;
  text-align: center;
  p {
    &:first-child {
      margin-top: 100px;
      margin-bottom: 30px;
      & + p {
        @extend %h2;
        color: $background_color;
        margin-bottom: 70px;
        & + p {
          @extend %h4;
          margin-bottom: 15px;
        }
      }
    }
  }
  a {
    display: flex;
    width: 317px;
    height: 68px;
    justify-content: center;
    align-items: center;
    @extend %body1;
    color: $background_color;
    text-decoration: none;
    border: 1px solid transparent;
    background-color: $accent_color2;
    border-radius: 50px;
    margin-bottom: 140px;
    margin-left: auto;
    margin-right: auto;
  }
}

.slick-slide {
  // margin: 0 -100px;
}
.slick-list {
  // margin: 0 100px;
}

.second_block .block_form_left .block,
.second_block .blocks_from_right .block {
  min-width: 548px;
}

.select-styled {
  font-size: 18px;
}

.policy_content a {
  color: $accent_color;
  text-decoration: none;
}

.banner .like_svg {
  min-height: 171px;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999999;
  background-color: $background_color;
  svg {
    path {
      fill: $accent_color2;
    }
  }
}

// .second_block .block_form_left {
//   margin-bottom: 0px;
//   padding-bottom: 265px;
// }
// .for_second_block {
//   // position: relative;
//   .second_block {
//     position: fixed!important;
//     top: 0;
//     // box-sizing: border-box;
//   }
// }

.for_second_block {
  // margin-top: 500px;
  // position: relative;
}

.banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  // overflow-y: auto;

  pointer-events: none;
  .like_svg {
    // margin-bottom: 0px;
    // padding-bottom: 150px;
  }
}

// .second_block {
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   z-index: 999;
// }

.banner::-webkit-scrollbar {
  width: 0;
}

.key_facts_flex div p:first-child {
  font-family: "Eskal Font4You";
  font-weight: 400;
}

.for_slider_block .slide_content p:nth-child(2) {
  color: #fff5f5;
}

#trp-floater-ls {
  display: none !important;
}

// popup cookie

.popup-modal {
  position: fixed;
  z-index: 99999;
  bottom: 0;
  right: 0;
  margin: auto;
  // height: 100vh;
  // width: 100vw;
  max-width: 1055px;
  transition: $transition;
  opacity: 1;

  .container {
    margin-right: 0;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;

    * {
      pointer-events: none !important;
    }
  }

  .cookie-policy_content {
    background-color: $main_color;
    color: $background_color;
    padding: 31px;
    padding-right: 25px;
    max-width: 100%;
    width: 100%;
    min-width: unset;
    position: relative;
    z-index: 1;
    border: none;
    border-top-left-radius: 50px;

    display: flex;
    align-items: center;
    column-gap: 10px;

    p {
      @extend %body3;
      max-width: 608px;
    }

    .button-no-marquee {
      color: $background_color;
      background-color: $accent_color2;
      padding: 7px 14px;
      transition: $transition;
      border-radius: 40px;
      outline: none;
      border: none;
      text-transform: uppercase;
      @extend %body3;

      &:hover,
      &:focus {
        transition: $transition;
        background-color: $accent_color;
        text-decoration: none;
      }
    }

    .button-black {
      background-color: $accent_color;
      margin-left: 11px;

      &:hover,
      &:focus {
        background-color: $accent_color2;
      }
    }

    button#cookie-close {
      @extend %body3;
      position: relative;
      transition: $transition;
      color: $background_color;
      background-color: $background_color;
      border: 1px solid $accent_color;
      width: 39px;
      height: 39px;
      border-radius: 100%;

      &::after,
      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        background-color: $accent_color;
        width: 19px;
        height: 1px;
        transition: $transition;
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }

      &:hover,
      &:focus {
        background-color: $accent_color;
        border-color: $background_color;
        transition: $transition;

        &::after,
        &::before {
          background-color: $background_color;
          transition: $transition;
        }
      }
    }
  }
}

.thank-you-page {
  text-align: center;
  margin-top: 102px;
  margin-bottom: 185px;

  h4 {
    margin: auto;
    margin-top: 10px;
    margin-bottom: 70px;
    max-width: 418px;
    color: $accent_color;
  }

  a {
    background-color: $accent_color;
    color: $background_color;
    padding: 17px 95px;
    border-radius: 50px;
    transition: $transition;

    &:hover,
    &:focus {
      text-decoration: none;
      background-color: $accent_color2;
      transition: $transition;
    }
  }
}

.mini-cart-icon {
  position: fixed;
  z-index: 999;
  // top: 0;
  bottom: 30%;
  right: 21px;
  margin: auto;
  width: 72px;
  height: 72px;
  border-radius: 100%;
  background-color: $accent_color;
  outline: none;
  border: none;

  &-count {
    width: 26px;
    height: 26px;
    background-color: $accent_color;
    border-radius: 100%;
    position: absolute;
    bottom: 15px;
    left: 18px;
    color: $accent_color2;
    @extend %body3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mini-shop-popup {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  right: 140px;
  margin: auto;
  max-height: 484px;
  width: calc(100vw / 12 * 3);
  min-width: 305px;

  padding: 22px 13px 26px 18px;
  overflow: hidden;

  background-color: $background_color;
  box-shadow: 0px 3px 10px rgba(91, 85, 151, 0.5);
  border: 1px solid $accent_color2;
  border-top: 5px solid $accent_color2;
  border-radius: 0 0 15px 15px;
  transition: $transition;

  .wc-menu-cart {
    &__header {
      display: flex;
      margin-bottom: 15px;
      align-items: center;
      color: $accent_color2;
    }

    &__close-button {
      margin-left: auto;
      background-color: transparent;
      outline: none;
      border: none;

      * {
        transition: $transition;
      }

      circle {
        fill: rgba(91, 85, 151, 0);
      }

      &:hover,
      &:focus {
        rect {
          fill: $accent_color;
        }

        circle {
          fill: $accent_color2;
        }
      }
    }

    &__products {
      max-height: 258px;
      overflow-y: scroll;
      border-top: 1px solid $accent_color;
      border-bottom: 1px solid $accent_color;

      padding-top: 15px;
      padding-bottom: 15px;
    }

    &__product {
      margin-bottom: 15px;
      @extend %body4;
      padding-right: 10px;

      display: flex;
      justify-content: space-between;

      .left-side {
      }

      .right-side {
        position: relative;

        input[type="number"] {
          width: 70px;
          height: 32px;
          border-radius: 20px;
          border: 1px solid $accent_color2;
          padding: 6px 10px 4px 14px;
          background-color: transparent;
          color: $accent_color2;
          -webkit-appearance: textfield;
          -moz-appearance: textfield;
          appearance: textfield;

          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
          }
        }

        // input-number-arrow.svg
        button {
          background: url(../img/input-number-arrow.svg) no-repeat center
            transparent;
          width: 8px;
          height: 7px;
          outline: none;
          border: none;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 15px;

          &#input-up {
            top: 7px;
          }

          &#input-bottom {
            top: 18px;
            transform: rotate(180deg);
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .remove {
        margin-top: 3px;
        color: $accent_color !important;
        width: unset;
        height: unset;
        border-radius: unset;
        font-size: inherit;
        text-align: left;

        &:hover,
        &:focus {
          background-color: transparent;
          color: $accent_color2;
        }
      }
    }
  }

  .woocommerce-mini-cart {
    &__footer {
      margin-top: 15px;

      .total {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        color: $accent_color2;
      }

      .button {
        position: relative;
        height: unset;
        padding: 14px;
        width: 100%;
        background-color: $accent_color2;
        color: $background_color;
        border-radius: 50px;
        margin-top: 27px;
        text-align: center;
      }
    }
  }

  .woocommerce-mini-cart__empty-message__go-home {
    margin-top: 15px;

    .button {
      position: relative;
      padding: 0 !important;
      width: unset;
      height: unset;
      background-color: transparent;
      font-weight: 700;
    }
  }

  &.hide {
    opacity: 0;
    pointer-events: none !important;
    visibility: hidden;

    right: -30vw;

    * {
      pointer-events: none !important;
    }
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  // scrollbar-width: none;
  // scrollbar-color: red yellow;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: $accent_color2;
  border-radius: 3px;
}

*::-webkit-scrollbar-thumb {
  background-color: $accent_color;
  border-radius: 3px;
  width: 4px;
}

.container.container-fluid {
  max-width: 100%;
}

.translatepress-ru_RU {
  .homepage_pricing .inherit_flex .block p:first-child + p {
    font-size: 30px;
  }
}

.added_to_cart {
  display: none !important;
}

.add_to_cart_button {
  &::after {
    width: 0;
    height: 0;
    transition: $transition;
  }
}

.loading {
  position: relative;

  &:after {
    content: "";
    // position: absolute;
    height: 21px;
    width: 21px;
    // margin: auto;
    margin-left: 8px;
    background: url(../img/fa-loading-circle.svg) no-repeat top;
    background-size: 8px;
    border: 1px solid $background_color;
    border-radius: 100%;
    animation: 2s linear infinite loading-animation;
  }
}

.popup-added-to-card {
  position: fixed;
  z-index: 99;
  bottom: 42px;
  left: 20px;
  background-color: $accent_color2;
  color: $background_color;
  padding: 14px 31px;

  border-radius: 50px;

  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: calc((100vw - 40px) / 12 * 3);
  min-width: 305px;
  column-gap: 20px;
  transition: $transition;

  p {
    white-space: nowrap;
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;

    * {
      transition: $transition;
    }

    &:hover,
    &:focus {
      circle,
      line {
        stroke: $accent_color;
      }
    }
  }

  &.hide {
    opacity: 0;
    pointer-events: none !important;
    visibility: hidden;
    left: -30vw;
  }
}



.new_custom_block_about {
  .inherit_flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 104px;
    p {
      @extend %h2;
      color: var(--background-color, #FFF5F5);
      max-width: 913px;
    }
    .for_button {
      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 183px;
        height: 183px;
        border: 1px solid $background_color;
        border-radius: 50%;
        overflow: hidden;
        span {
          width: 142px;
          height: 142px;
          background-color: $accent_color2;
          display: flex;
          align-items: center;
          justify-content: center;
          @extend %body2;
          color: #fff5f5;
          border-radius: 50%;
          transition: 0.4s cubic-bezier(0.87, 0, 0.13, 1);
        }
        &:hover {
          span {
            width: 100%;
            height: 100%;
            // transform: scale(1.8);
            // font-size: 18px;
          }
        }
      }
    }
  }
}


.for_white_about_block {
  padding-top: 140px;
  background-color: #FFF5F5;
  margin-top: -123px;
  position: relative;
  z-index: 2;
  border-radius: 50px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-bottom: 100px;
  position: relative;
  z-index: 55;

  .new_about_title {
    @extend %h2;
    color: var(--main-color, #363333);
    margin-top: 140px;
    margin-bottom: 35px;
    max-width: 851px;
    &+div {
      max-width: 914px;
      margin-left: 100px;
      p {
        @extend %body1;
        color: var(--main-color, #363333);
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 165px;
        }
      }
    }
  }


  .blocks_from_right,
  .block_form_left {
    margin-bottom: 177px;
    display: flex;
    flex-wrap: nowrap;
    .block {
      margin-right: 20px;
      &:last-child {
        margin-right: 0px;
      }
      padding-top: 34px;
      padding-left: 18px;
      padding-right: 18px;
      box-sizing: border-box;
      width: 548px;
      height: 277px;
      border: 1px solid $accent_color;
      background: $accent_color;
      p {
        color: $background_color;
        &:first-child {
          @extend %h3;
          margin-bottom: 15px;
          & + p {
            @extend %body2;
          }
        }
      }
    }
  }


  .marquee-infinite {
    color: $accent_color;
    p {
      color: $accent_color;
    }
  }
}


.custom_numbers_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .word-length_wrap {
    max-width: 183px;
    input {
      height: 60px;
    }
  }
  .for_buttons {
    height: 60px;
    width: calc(100% - 194px);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    div {
      display: inline-flex;
      justify-content: space-between;
      button {
        border: none;
        outline: none;
        display: inline-flex;
        width: 70px;
        height: 27px;
        justify-content: center;
        align-items: center;
        @extend %body5;
        color: var(--background-color, #FFF5F5);
        border-radius: 10px;
        transition: 0.2s ease-in-out;
        line-height: 100%!important;
      }
      &.buttons_plus {
        button {
          background-color: $accent_color2;
          &:hover {
            background-color: $background_color;
            color: $accent_color2;
          }
        }
      }
      &.buttons_minus {
        button {
          background-color: $main_color;
          &:hover {
            background-color: $background_color;
            color: $main_color;
          }
        }
      }
    }
  }
}

// Always in the end
@import "_media.scss";
// ___________________
